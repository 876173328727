/** These settings match up with Conditions that are set on our Query Pipelines within Coveo  */
export const ASSET_STORE_USER_GROUP = 'assetStoreUsers';
export const ASSET_STORE_INTERNAL_USER_GROUP = 'assetStoreInternalUsers';
export const ASSET_STORE_EXPERIMENT_USER_GROUP = 'assetStoreUsers_Experiment';
export const SECURITY_PROVIDER = 'Email Security Provider';

export enum CoveoSearchHub {
  SEARCH = 'Assetstore_Search',
  LISTING = 'Assetstore_Listing',
}
