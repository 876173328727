'use client';

import {
  COOKIE_EXPIRY_DAYS,
  CURRENCY_COOKIE_NAME,
} from '@lib/constants/cookies';
import Cookie from 'js-cookie';
import { useRouter } from 'next/navigation';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DEFAULT_CURRENCY, SUPPORTED_CURRENCIES } from 'utils';

export type CurrencyContextType = {
  currency: string;
  setCurrency: (newCurrency: string) => void;
};

export const CurrencyContext = createContext<CurrencyContextType | undefined>(
  undefined
);

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};

export const CurrencyProvider = ({ children }: PropsWithChildren<{}>) => {
  const currencyCookieValue = Cookie.get(CURRENCY_COOKIE_NAME) ?? '';
  const defaultCurrency = SUPPORTED_CURRENCIES.includes(currencyCookieValue)
    ? currencyCookieValue
    : DEFAULT_CURRENCY;
  const [currency, setCurrency] = useState<string>(defaultCurrency);
  const router = useRouter();

  const contextValue: CurrencyContextType = useMemo(() => {
    const changeCurrency = (newCurrency: string) => {
      setCurrency(newCurrency);

      /**
       * We're setting this cookie for ASv2 backwards-compatibility.
       */
      Cookie.set(CURRENCY_COOKIE_NAME, newCurrency, {
        expires: COOKIE_EXPIRY_DAYS,
      });

      router.refresh();
    };

    return {
      currency,
      setCurrency: changeCurrency,
    };
  }, [currency, router]);

  return (
    <CurrencyContext.Provider value={contextValue}>
      {children}
    </CurrencyContext.Provider>
  );
};
